(<template>
  <nav class="mobile-nav">
    <nav-prof-link />
    <button v-if="userIsInterpreter && userHasInterpretation"
            class="mobile-nav__subnav-link mobile-nav__subnav-link--interpreter-id"
            @click="openInterpreterIdModal">{{ $gettext('My interpreter ID') }}</button>
    <button class="mobile-nav__subnav-link mobile-nav__subnav-link--accessibility"
            @click="openAccessibilityModal">{{ $gettext('Accessibility') }}</button>
    <router-link v-for="(routeObj, index) in filteredRoutes"
                 :key="index"
                 :to="$makeRoute(routeObj.route)"
                 :class="routeObj.classes"
                 class="mobile-nav__subnav-link">{{ routeObj.text }}</router-link>
    <!-- TODO: Original page is not working anymore and for proving everything is works suppliers must pass video test -->
    <!-- <a v-if="allowInterpretationServices || (userIsInterpreter && userHasInterpretation)"
       href="https://test.webrtc.org"
       target="_blank"
       class="mobile-nav__subnav-link mobile-nav__subnav-link--net-test">{{ $gettext('Network test') }}</a> -->
    <locale-select :header-lang-select="true"
                   class="mobile-nav__subnav-link mobile-nav__select mobile-nav__select--locale" />
    <jobs-scope-select v-if="userIsEnterpriseMember && (allowInterpretationServices || (userIsInterpreter && userHasInterpretation))"
                       class="mobile-nav__subnav-link mobile-nav__select mobile-nav__select--scope" />
    <button v-if="userIsInterpreter"
            class="mobile-nav__subnav-link mobile-nav__subnav-link--help"
            @click="openHelpcenterModal">{{ $gettext('Help center') }}</button>
    <a v-else
       :href="supportLink"
       target="_blank"
       class="mobile-nav__subnav-link mobile-nav__subnav-link--help">{{ $gettext('Help center') }}</a>
    <button class="mobile-nav__subnav-link mobile-nav__subnav-link--chat"
            @click="openHelpscout">{{ $gettext('Chat') }}</button>
    <button class="mobile-nav__subnav-link mobile-nav__subnav-link--sign-out"
            @click="logOut">{{ $gettext('Sign out') }}</button>
  </nav>
</template>)

<script>
  import {mapGetters} from 'vuex';
  import NavProfileLink from '@/components/navigations/sh_MobileNavProfileLink';
  import JobsScopeSelect from '@/components/shared_components/JobsScopeSelect';

  export default {
    components: {
      'nav-prof-link': NavProfileLink,
      'jobs-scope-select': JobsScopeSelect
    },
    computed: {
      ...mapGetters('UserInfoStore', [
        'userIsInterpreter',
        'userIsSeller',
        'userHasInterpretation',
        'userIsNotEnterpriseMember',
        'userIsBusiness',
        'userIsEnterpriseMember',
        'userCanShowStatistics',
        'allowInterpretationServices',
        'userIsManager'
      ]),
      supportLink() {
        return this.userIsInterpreter
          ? 'https://support.salita.no/collection/362-tolk'
          : 'https://support.salita.no/collection/380-tolkebruker';
      },
      isMobileApp() { return this.$store.state.mobileApp; },
      routes() {
        return {
          documents: (this.userIsInterpreter) ? {
            route: {name: 'ServerDocs'},
            classes: ['mobile-nav__subnav-link--docs'],
            text: this.$gettext('Documents')
          } : false,
          payoutPreferences: (this.userIsInterpreter) ? {
            route: {name: 'ServerPayouts'},
            classes: ['mobile-nav__subnav-link--payments'],
            text: this.$gettext('Payout preferences')
          } : false,
          calendar: (this.userIsInterpreter || !this.allowInterpretationServices) ? false : {
            route: {name: 'BuyerCalendar', query: {view: 'day', date: this.$moment().format('YYYY-MM-DD')}},
            classes: ['mobile-nav__subnav-link--calendar'],
            text: this.$gettext('Calendar')
          },
          myCompany: this.userIsEnterpriseMember ? {
            route: {name: 'BuyerCompany'},
            classes: ['mobile-nav__subnav-link--comp-info'],
            text: this.$gettext('My company')
          } : false,
          password: {
            route: this.userIsInterpreter ? {name: 'ServerChangePassword'} : {name: 'BuyerChangePassword'},
            classes: ['mobile-nav__subnav-link--password'],
            text: this.$gettext('Change password')
          },
          notifications: (this.userIsInterpreter || !this.allowInterpretationServices) ? false : {
            route: {name: 'BuyerPreferencesNotifications'},
            classes: ['mobile-nav__subnav-link--pref'],
            text: this.$gettext('Notifications')
          },
          settings: (this.userIsInterpreter && this.allowInterpretationServices) ? {
            route: {name: 'ServerPreferences'},
            classes: ['mobile-nav__subnav-link--pref'],
            text: this.$gettext('Settings')
          } : false,
          videoTest: (this.isMobileApp || !this.allowInterpretationServices) ? false : {
            route: this.userIsInterpreter ? {name: 'ServerTestVideo'} : {name: 'BuyerTestVideo'},
            classes: ['mobile-nav__subnav-link--video-test'],
            text: this.$gettext('Video test')
          },
          statistics: (this.userCanShowStatistics && this.allowInterpretationServices) ? {
            route: {name: 'BuyerStatisticsChart', params: {chartId: 'line_type'}},
            classes: ['mobile-nav__subnav-link--statistics'],
            text: this.$gettext('Statistics')
          } : false,
          tickets: {
            route: {name: 'BuyerTicketSystem', query: {view: 'opened', page: 1}},
            classes: ['mobile-nav__subnav-link--tickets'],
            text: this.$gettext('Tickets')
          },
          payments: (this.userIsNotEnterpriseMember && this.allowInterpretationServices) ? {
            route: this.$makeRoute({name: 'BuyerPayments'}),
            classes: ['mobile-nav__subnav-link--payments'],
            text: this.$gettext('Payments')
          } : false
        };
      },
      interpreterRoutes() {
        return {
          assignments: (this.userIsInterpreter && this.userHasInterpretation && this.userIsSeller) ? {
            route: this.assignmentsLink,
            classes: ['mobile-nav__subnav-link--assignments'],
            text: this.$gettext('Assignments')
          } : false,
          calendar: (this.userIsInterpreter && this.userHasInterpretation) ? {
            route: {
              name: 'ServerCalendar',
              query: {
                view: 'day',
                date: this.$moment().format('YYYY-MM-DD')
              }
            },
            classes: ['mobile-nav__subnav-link--calendar'],
            text: this.$gettext('Calendar')
          } : false,
          documents: (this.userIsInterpreter) ? {
            route: {name: 'ServerDocs'},
            classes: ['mobile-nav__subnav-link--docs'],
            text: this.$gettext('Documents')
          } : false,
          payoutPreferences: (this.userIsInterpreter) ? {
            route: {name: 'ServerPayouts'},
            classes: ['mobile-nav__subnav-link--payments'],
            text: this.$gettext('Payout preferences')
          } : false,
          password: {
            route: {name: 'ServerChangePassword'},
            classes: ['mobile-nav__subnav-link--password'],
            text: this.$gettext('Change password')
          },
          settings: (this.userIsInterpreter && this.userHasInterpretation) ? {
            route: {name: 'ServerPreferences'},
            classes: ['mobile-nav__subnav-link--pref'],
            text: this.$gettext('Settings')
          } : false,
          videoTest: (this.isMobileApp || !this.userHasInterpretation) ? false : {
            route: {name: 'ServerTestVideo'},
            classes: ['mobile-nav__subnav-link--video-test'],
            text: this.$gettext('Video test')
          }
        };
      },
      buyerRoutes() {
        return {
          myCompany: this.userIsEnterpriseMember ? {
            route: {name: 'BuyerCompany'},
            classes: ['mobile-nav__subnav-link--comp-info'],
            text: this.$gettext('My company')
          } : false,
          calendar: (!this.allowInterpretationServices) ? false : {
            route: {name: 'BuyerCalendar', query: {view: 'day', date: this.$moment().format('YYYY-MM-DD')}},
            classes: ['mobile-nav__subnav-link--calendar'],
            text: this.$gettext('Calendar')
          },
          interpreters: (this.allowInterpretationServices) ? {
            route: {
              name: 'BuyerInterpreters',
              query: {
                page: 1,
                availability_date: this.$moment().format('YYYY-MM-DD'),
                langFrom: '3'
              }
            },
            classes: ['mobile-nav__subnav-link--interpreters'],
            text: this.$gettext('Interpreters')
          } : false,
          password: {
            route: {name: 'BuyerChangePassword'},
            classes: ['mobile-nav__subnav-link--password'],
            text: this.$gettext('Change password')
          },
          notifications: (!this.allowInterpretationServices) ? false : {
            route: {name: 'BuyerPreferencesNotifications'},
            classes: ['mobile-nav__subnav-link--pref'],
            text: this.$gettext('Notifications')
          },
          videoTest: (this.isMobileApp || !this.allowInterpretationServices) ? false : {
            route: {name: 'BuyerTestVideo'},
            classes: ['mobile-nav__subnav-link--video-test'],
            text: this.$gettext('Video test')
          },
          statistics: (this.userCanShowStatistics && this.allowInterpretationServices) ? {
            route: {name: 'BuyerStatisticsChart', params: {chartId: 'line_type'}},
            classes: ['mobile-nav__subnav-link--statistics'],
            text: this.$gettext('Statistics')
          } : false,
          tickets: {
            route: {name: 'BuyerTicketSystem', query: {view: 'opened', page: 1}},
            classes: ['mobile-nav__subnav-link--tickets'],
            text: this.$gettext('Tickets')
          },
          payments: (this.userIsNotEnterpriseMember && this.allowInterpretationServices) ? {
            route: this.$makeRoute({name: 'BuyerPayments'}),
            classes: ['mobile-nav__subnav-link--payments'],
            text: this.$gettext('Payments')
          } : false
        };
      },
      managerRoutes() {
        return {};
      },
      roleBaseRoutes() {
        if (this.userIsInterpreter) return this.interpreterRoutes;
        else if (this.userIsManager) return this.managerRoutes;
        else return this.buyerRoutes;
      },
      filteredRoutes() {
        const routes = {};
        for (const key of Object.keys(this.roleBaseRoutes)) {
          if (this.roleBaseRoutes[key]) {
            routes[key] = this.roleBaseRoutes[key];
          }
        }
        return routes;
      },
      assignmentsLink() {
        return this.$makeRoute({
          name: 'ServerAllAssignments',
          query: {
            view: 'current',
            sortConfirmedBy: 1,
            sortAvailableBy: 1,
            pageConfirmedBy: 1,
            pageAvailableBy: 1
          }
        });
      },
    },
    methods: {
      logOut() {
        this.$store.dispatch('TTAuthStore/logOut').then(() => {
          // set cookie for preventing mobile app redirect after clicking on logout button
          this.$cookieManager.setCookie('preventMobileAppRedirect', true, {path: '/', expires: 2592000});
          // Google Tag Manager
          this.trackLogOutGA();
          // End Google Tag Manager
          setTimeout(() => {
            this.$goToExternalLoginPage();
          }, 0);
        });
      },
      trackLogOutGA() {
        this.$skGA.emitEvent({eventCategory: 'authentication', eventAction: 'logout', eventLabel: 'signout', value: 0});
      },
      openInterpreterIdModal() {
        this.$store.commit('ModalStore/setModal', {
          component: 'interpreter-id-modal',
          width: 333
        });
      },
      openHelpscout() {
        if (window && window.Beacon) {
          window.Beacon('open');
        }
      },
      closeModal() {
        this.$emit('closemodal');
      },
      openHelpcenterModal() {
        this.closeModal();
        this.$store.commit('ModalStore/setModal', {
          component: 'helpcenter-modal',
        });
      },
      openAccessibilityModal() {
        this.closeModal();
        this.$store.commit('ModalStore/setModal', {
          component: 'accessibility-modal',
        });
      },
    }
  };
</script>

<style src="@assets/css/mobile_sub_navigation.css"></style>
